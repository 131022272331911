import {
  AccountCircle,
  Assignment,
  Engineering,
  ExitToApp,
  Home,
} from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import QuizIcon from '@mui/icons-material/Quiz';
import {
  Badge,
  BottomNavigation,
  BottomNavigationAction,
  Button,
  Collapse,
  Container,
  Fab,
  Link,
  Stack,
  SvgIcon,
  Tab,
  Tabs,
} from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import InstallationStatus from '../components/dashboard/InstallationStatus';
import LanguageMenu from '../components/LanguageMenu';
import Logo from '../components/Logo';
import { SideNavItem } from '../components/nav/SideNavItem';
import SearchOnAnimation from '../components/SearchOnAnimation';
import logo from '../Image/company_logo.png';
import { getProposalsByUserId } from '../services/CustomerService';
import { getUserDetails } from '../services/UserDetailsService';
import { usePageTitleStore } from '../store/PageTitleStore';
import { useSearchAnimate } from '../store/SearchAnimateStore';
import { isLoggedIn } from '../utils/utils';
import SEO from './SEO';

const drawerWidth = 240;
const navItems = [
  {
    displayName: 'Dashboard',
    pathname: '/dashboard',
    tabIndex: 0,
    icon: (
      <SvgIcon fontSize='small'>
        <Home />
      </SvgIcon>
    ),
  },
  {
    displayName: 'Account',
    pathname: '/profile',
    tabIndex: 1,
    icon: (
      <SvgIcon fontSize='small'>
        <AccountCircle />
      </SvgIcon>
    ),
  },
  {
    displayName: 'Shortlists',
    pathname: '/en/projects',
    tabIndex: 2,
    icon: (
      <SvgIcon fontSize='small'>
        <Assignment />
      </SvgIcon>
    ),
  },
  {
    displayName: 'Help',
    external: true,
    pathname: 'https://forms.gle/FYBfYapmfg77pZkz9',
    tabIndex: 3,
    icon: (
      <SvgIcon fontSize='small'>
        <QuizIcon />
      </SvgIcon>
    ),
  },
];

export const DashboardLayout = ({
  children,
  announcementBar,
  installationTimeline,
  propertyDetails,
}) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [userDetails, setUserDetails] = React.useState({});
  const [shortlistCount, setShortlistCount] = React.useState(0);
  const [navValue, setNavValue] = useState(null);
  const { isSearchOver, setIsSearchOver } = useSearchAnimate();
  const { useTitle, useImage, useDescription } = usePageTitleStore();
  const theme = useTheme();
  const [currPageIndex, setCurrPageIndex] = useState(0);

  const history = useNavigate();
  const location = useLocation();

  const navItems2 = [
    {
      displayName: 'Dashboard',
      pathname: '/en/dashboard',
      tabIndex: 0,
      icon: (
        <SvgIcon fontSize='small'>
          <Home />
        </SvgIcon>
      ),
    },
    {
      displayName: 'Shortlists',
      external: true,
      pathname: '/en/projects',
      tabIndex: 1,
      icon: (
        <Badge badgeContent={shortlistCount} color='primary'>
          <SvgIcon fontSize='small'>
            <Assignment />
          </SvgIcon>
        </Badge>
      ),
    },
    {
      displayName: 'Account',
      pathname: '/en/profile',
      tabIndex: 2,
      icon: (
        <SvgIcon fontSize='small'>
          <AccountCircle />
        </SvgIcon>
      ),
    },
  ];

  const [userDetailsFetched, setUserDetailsFetched] = useState(false);

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (isLoggedIn()) {
        const data = await getUserDetails();
        setUserDetails(data);
        setUserDetailsFetched(true);
      }
    };
    fetchUserDetails();
  }, []);

  useEffect(() => {
    const fetchProposals = async () => {
      if (userDetailsFetched) {
        const shortlists = await getProposalsByUserId();
        setShortlistCount(shortlists.length);
      }
    };
    fetchProposals();
  }, [userDetailsFetched]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setIsSearchOver(true);
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    history({ pathname: '/' });
  };

  const handleSupportButtonClick = () => {
    window.open('https://forms.gle/FYBfYapmfg77pZkz9', '_blank');
  };

  const drawer = (
    <>
      <Stack
        component='ul'
        spacing={1}
        sx={{
          listStyle: 'none',
          py: 5,
          px: 3,
          m: 0,
          mt: 0,
          height: '100%',
        }}
        onClick={handleDrawerToggle}
      >
        <Typography variant='h6' pb={4}>
          Hi, {userDetails?.name ?? 'User'}
        </Typography>
        {navItems.map((item) => {
          const active = item.path ? location.pathname === item.path : false;
          return (
            <SideNavItem
              active={active}
              disabled={item.disabled}
              external={item.external}
              icon={item.icon}
              key={item.displayName}
              path={item.pathname}
              title={item.displayName}
            />
          );
        })}
        <Box
          sx={{
            display: { xs: 'flex' },
            cursor: 'pointer',
            height: '30px',
            width: '100px',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '5px',
            '&:hover': { bgcolor: '#f0faf3' },
          }}
          color={'neutral.main'}
        >
          {isLoggedIn() && (
            <Stack
              direction={'row'}
              ml={2}
              alignItems={'center'}
              spacing={2}
              onClick={() => handleLogout()}
            >
              <SvgIcon sx={{ height: '18px', width: '18px' }}>
                <ExitToApp />
              </SvgIcon>

              <Typography
                variant='subtitle1'
                fontSize={'15px'}
                fontWeight={540}
              >
                Logout
              </Typography>
            </Stack>
          )}
        </Box>
        <Box marginTop={'auto !important'} sx={{ alignSelf: 'center' }}>
          <Logo />
        </Box>
        <Stack alignItems={'center'}>
          <Link href='https://www.vaysolar.com/docs/privacy/' target='_blank'>
            <Typography fontSize={theme.typography.caption.fontSize}>
              Privacy Policy
            </Typography>
          </Link>
          <Typography
            fontSize={theme.typography.caption.fontSize}
            color='text.secondary'
          >
            © Copyright | VaySolar
          </Typography>
        </Stack>
      </Stack>
    </>
  );

  React.useEffect(() => {
    navItems2.forEach((obj) => {
      if (obj.pathname === location.pathname) {
        setCurrPageIndex(obj.tabIndex);
      }
    });
  }, [location.pathname]);

  return (
    <>
      <SEO
        title={useTitle}
        image={useImage}
        description={useDescription}
        name='VaySolar'
        type='article'
      />
      <nav>
        <Drawer
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: 'block',
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <SearchOnAnimation
        isSearchOver={isSearchOver}
        propertyDetails={propertyDetails}
      />

      <Collapse in={isSearchOver} collapsedSize={0} unmountOnExit mountOnEnter>
        <Box>
          <Container
            sx={{
              paddingInline: '0px !important',
              boxShadow: {
                xs: '0px 2px 2px rgba(0, 0, 0, 0.25)',
                sm: '0px 1px 1px rgba(0, 0, 0, 0.25)',
              },
              marginTop: { xs: '8px', sm: '0px' },
              maxWidth: '100vw !important',
            }}
          >
            <Box
              display={'flex'}
              alignItems={'center'}
              flexDirection={'row'}
              height={{ xs: '48px', sm: '48px' }}
              justifyContent={'space-between'}
              paddingInline={'16px'}
            >
              <Stack
                direction={'row'}
                alignItems={{ xs: 'center', md: 'flex-end' }}
              >
                <IconButton
                  color='inherit'
                  aria-label='open drawer'
                  edge='start'
                  onClick={handleDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
                <Box
                  sx={{
                    display: { xs: 'none', md: 'flex' },
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                    width: { xs: '80%', sm: 'auto' },
                  }}
                  onClick={() => history({ pathname: '/dashboard' })}
                >
                  <img
                    src={logo}
                    alt='Logo'
                    style={{
                      width: `120px`,
                      height: `40px`,
                      cursor: 'pointer',
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: { xs: 'flex', md: 'none' },
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={() => history({ pathname: '/dashboard' })}
                >
                  <Logo />
                </Box>
              </Stack>
              {/* <Stack direction={'row'} alignItems={'center'}>
                {!isMobile && (
                  <>
                    <DashboardButton />
                    <ProjectsButton />
                    <ProfileButton />
                  </>
                )}
              </Stack> */}

              <Tabs
                sx={{
                  display: { xs: 'none', md: 'block' },
                  ml: { md: 3, sm: 2, xs: 1 },
                  alignSelf: 'flex-end',
                  height: '48px',
                }}
                value={currPageIndex}
              >
                {navItems2.map((item) => (
                  <Tab
                    icon={item.icon}
                    key={item.displayName}
                    iconPosition='start'
                    sx={{
                      height: '48px',
                    }}
                    label={item.displayName}
                    onClick={() => history({ pathname: item.pathname })}
                  />
                ))}
              </Tabs>
              <Stack
                direction={'row'}
                justifyContent={'flex-end'}
                alignItems={'center'}
                pr={{ xs: '0px', sm: '12px' }}
                spacing={'8px'}
              >
                <LanguageMenu />
                {isLoggedIn() ? (
                  <Button
                    endIcon={<ExitToApp />}
                    onClick={() => handleLogout()}
                    sx={{
                      display: { xs: 'none', sm: 'flex' },
                    }}
                  >
                    Logout
                  </Button>
                ) : (
                  ''
                )}
              </Stack>
            </Box>
          </Container>
        </Box>
      </Collapse>
      <Collapse
        in={isSearchOver}
        collapsedSize={0}
        unmountOnExit
        mountOnEnter
        timeout={1800}
      >
        {announcementBar ? (
          <InstallationStatus
            propertyDetails={propertyDetails}
            installationTimeline={installationTimeline}
          />
        ) : (
          ''
        )}
      </Collapse>
      <Collapse in={isSearchOver} collapsedSize={0} unmountOnExit mountOnEnter>
        <Box sx={{ maxWidth: '1048px', mx: 'auto' }}>
          <Box pb={'120px'}>{children}</Box>
          <Fab
            sx={{
              position: 'fixed',
              bottom: { md: 24, xs: 72 },
              right: { md: 96, xs: 16 },
            }}
            color='primary'
            onClick={handleSupportButtonClick}
            variant='extended'
          >
            <Engineering sx={{ mr: 1 }} />
            Expert Call
          </Fab>
        </Box>
      </Collapse>
      <Box
        sx={{
          display: { xs: 'block', sm: 'none' },
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: theme.zIndex.appBar,
        }}
      >
        <BottomNavigation
          showLabels
          value={navValue}
          sx={{
            boxShadow: '2px 2px 8px 2px rgba(0,0,0,0.3)',
            borderRadius: '9px',
            height: '62px',
          }}
          onChange={(event, newValue) => {
            setNavValue(newValue);
            switch (newValue) {
              case 0:
                history('/dashboard');
                break;
              case 1:
                history('/projects');
                break;
              case 2:
                history('/profile');
                break;
              default:
                break;
            }
          }}
        >
          <BottomNavigationAction
            label='My offers'
            sx={{
              fontWeight: 'bold',
              color:
                location.pathname === '/en/dashboard' ? 'primary.main' : '',
            }}
            icon={<Home />}
            selected={navValue === 0}
          />
          <BottomNavigationAction
            label='Shortlists'
            sx={{
              fontWeight: 'bold',
              color: location.pathname === '/en/projects' ? 'primary.main' : '',
            }}
            icon={
              <Badge
                badgeContent={shortlistCount}
                color={location.pathname === '/en/projects' ? 'primary' : ''}
              >
                <Assignment />
              </Badge>
            }
            selected={navValue === 1}
          />
          <BottomNavigationAction
            sx={{
              fontWeight: 'bold',
              color: location.pathname === '/en/profile' ? 'primary.main' : '',
            }}
            label='Account'
            icon={<AccountCircle />}
            selected={navValue === 2}
          />
        </BottomNavigation>
      </Box>
    </>
  );
};
