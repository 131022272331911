import { InstallationTimeline } from '../utils/app.enums';
import apiClient from './ApiClient';

export const addCustomerDetails = async (customerDetails) => {
  const res = await apiClient.post(
    '/portal/customer/add-details',
    customerDetails,
  );
  return res.data;
};

export const addSiteVisitDetails = async (
  siteVisitDetails,
  propertyDetailsId,
) => {
  const res = await apiClient.post(
    `/portal/customer/add-site-visit-details/${propertyDetailsId}`,
    siteVisitDetails,
  );
  return res.data;
};

export const getProjectEstimate = async (
  region,
  propertyType,
  sanctionedLoad,
  billAmount,
  shadowFreeArea,
  billMonth,
  plantSize,
) => {
  const queryString = `?region=${region}&propertyType=${propertyType}&sanctionedLoad=${sanctionedLoad}&billAmount=${billAmount}&shadowFreeArea=${shadowFreeArea}&billMonth=${billMonth}&custom_plant_size=${plantSize}`;
  const res = await apiClient.get(
    '/portal/customer/get-property-estimates' + queryString,
  );
  return res.data;
};

export const getPlantSizeOptions = async (
  region,
  propertyType,
  billAmount,
  billMonth,
  sanctionedLoad,
  shadowFreeArea,
  loanPercentage,
  customPlantSize,
) => {
  const queryString = `?region=${region}&propertyType=${propertyType}&billAmount=${billAmount}&billMonth=${billMonth}&sanctionedLoad=${sanctionedLoad}&shadowFreeArea=${shadowFreeArea}&loanPercentage=${loanPercentage}&customPlantSize=${customPlantSize}`;
  const res = await apiClient.get(
    '/portal/customer/plant-size-options' + queryString,
  );
  return res.data;
};

export const getUserProperties = async () => {
  const res = await apiClient.get('/portal/customer/property-details');
  return res.data;
};

export const updateInstallationTimeline = async (
  propertyDetails,
  installationTimeline,
  feedback,
  additionalComments,
) => {
  let data = { installationTimeline };
  if (installationTimeline === InstallationTimeline.NOT_LOOKING) {
    data.metadata = {
      ...propertyDetails?.metadata,
      feedback: feedback,
      comments: additionalComments,
    };
  }
  const res = await apiClient.put(
    `/property-details/${propertyDetails?.id}`,
    data,
  );
  return res.data;
};

export const updatePropertyVerificationStatus = async (
  propertyId,
  verificationStatus,
) => {
  const res = await apiClient.put(`/property-details/${propertyId}`, {
    verificationStatus,
  });
  return res.data;
};

export const getProposalsByUserId = async () => {
  const res = await apiClient.get('/portal/customer/proposals');
  return res.data;
};

export const downloadDocument = async (docId) => {
  return (await apiClient.get(`/documents/download/${docId}`)).data;
};

export const checkPhoneNumber = async (phoneNumber) => {
  const res = await apiClient.get(
    `/auth/check-phone-number?phoneNumber=${phoneNumber}`,
  );
  return res.data;
};

export const sendVendorReminder = async (matchId) => {
  const res = await apiClient.post('/events', {
    type: 'SMS',
    category: 'CUSTOMER_INITIATES_CALL',
    categoryFields: {
      match_id: matchId,
    },
  });
  return res.data;
};

export const customerInitiatesCallEvent = async (customerId, vendorId) => {
  const res = await apiClient.post('/events', {
    type: 'SMS',
    category: 'CUSTOMER_INITIATES_CALL',
    categoryFields: {
      user_id: customerId,
      vendor_id: vendorId,
    },
  });
  return res.data;
};

export const requestQuote = async (userId, vendorId) => {
  const res = await apiClient.post('/events', {
    type: 'SMS',
    category: 'CUSTOMER_REQUESTS_QUOTE',
    categoryFields: {
      user_id: userId,
      vendor_id: vendorId,
    },
  });
  return res.data;
};

export const getVendorList = async () => {
  const res = await apiClient.get('/portal/customer/nearby-vendors');
  return res.data;
};

export const getVendorDetails = async (vendorId) => {
  const res = await apiClient.get(
    `/portal/customer/vendor-details/${vendorId}`,
  );
  return res.data;
};

export const getTokens = async (user) => {
  const res = await apiClient.post('/auth/token', user);
  return res.data;
};

export const uploadElectricityBill = async (
  propertyId,
  electricityBillFile,
) => {
  if (!electricityBillFile) {
    throw new Error('No file selected');
  }

  const formData = new FormData();
  formData.append('electricityBill', electricityBillFile);

  const headers = {
    'Content-type': 'multipart/form-data',
  };

  return await apiClient.put(`/property-details/${propertyId}`, formData, {
    headers,
  });
};

export const getCustomerDetails = async (userId) => {
  const res = await apiClient.get('/customers/' + userId);
  return res.data;
};

export const getRecommendations = async (propertyId) => {
  const res = await apiClient.get(`/portal/customer/recommendations`);
  return res.data;
};

export const updatePlantType = async (propertyId, plantType) => {
  const res = await apiClient.put(`/property-details/${propertyId}`, {
    plantType,
  });
  return res.data;
};

export const updatePlantSize = async (propertyId, plantSize) => {
  const res = await apiClient.put(`/property-details/${propertyId}`, {
    plantSize,
  });
  return res.data;
};

export const createMatch = async (customerId, vendorId, configId) => {
  const res = await apiClient.post('/matches', {
    customer: customerId,
    vendor: vendorId,
    status: 'CUSTOMER ENQUIRED',
    metadata: configId
      ? {
        configIds: [configId],
      }
      : {},
  });
  return res.data;
};

export const createVisit = async (matchId, visitDetails) => {
  const res = await apiClient.post(
    `/portal/customer/${matchId}/site-visit`,
    visitDetails,
  );
  return res.data;
};

export const updateIsConvertedStatus = async (
  recommendationId,
  isConverted,
) => {
  const res = await apiClient.put(
    `/customers/recommendations/${recommendationId}`,
    {
      isConverted,
    },
  );
  return res.data;
};

export const generateRecommendations = async () => {
  const res = await apiClient.post('/portal/customer/generate-recommendations');
  return res.data;
};

export const getVendorSitePhotoDetails = async (vendorId) => {
  const res = await apiClient.get(
    `/portal/customer/site-photo/${vendorId}`,
  );
  return res.data;
};

export const getVendorTopSitePhotoDetails = async () => {
  const res = await apiClient.get(
    `/portal/customer/top-site-photos`,
  );
  return res.data;
};