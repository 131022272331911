import { Edit, Security } from '@mui/icons-material';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Container,
  IconButton,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardStepper from '../components/dashboard/DashboardStepper';
import PlantSize from '../components/dashboard/PlantSize';
import SolarSearch from '../components/dashboard/SolarSearch';
import UserGoal from '../components/dashboard/UserGoal';
import EditPlantDetailsDialog from '../components/ui/EditPlantDetailsDialog';
import { DashboardLayout } from '../layout/DashboardLayout';
import {
  generateRecommendations,
  getProposalsByUserId,
  getRecommendations,
  getUserProperties,
  updatePlantSize,
  updatePlantType,
} from '../services/CustomerService';
import { getUserDetails } from '../services/UserDetailsService';
import { usePageTitleStore } from '../store/PageTitleStore';
import { useSearchAnimate } from '../store/SearchAnimateStore';
import { InstallationTimeline } from '../utils/app.enums';

const options = [
  {
    id: 1,
    title: 'Cost of solar',
    name: '₹45000 to 68000 per kW',
    icon: (
      <CurrencyRupeeIcon
        fontSize='large'
        sx={{
          color: 'primary.main',
        }}
      />
    ),
    details: 'Depends on size, technology, brands, structure, warranties',
    href: 'https://www.vaysolar.com/docs/research/cost/',
    production: '10 units',
  },
  {
    id: 2,
    title: 'Benefits of solar',
    name: '₹2-3 per unit (up to 75% less)',
    icon: (
      <EnergySavingsLeafIcon
        fontSize='large'
        sx={{
          color: 'primary.main',
        }}
      />
    ),
    details:
      "You can reduce your electricity bills by up to 90%. Property's value also increases in the market!",
    href: 'https://www.vaysolar.com/docs/research/benefits/',
    production: '20 units',
  },
  {
    id: 3,
    title: 'How solar works?',
    name: 'Cells create energy from sunlight',
    icon: (
      <SolarPowerIcon
        fontSize='large'
        sx={{
          color: 'primary.main',
        }}
      />
    ),
    details: 'Solar energy is the cheapest form of energy source available',
    href: 'https://www.vaysolar.com/docs/research/how-it-works/',
    production: '40 units',
  },
];

const DashboardPage = () => {
  const { updateTitle, updateDescription } = usePageTitleStore();
  const [vendorData, setVendorData] = useState({});
  const [propertyDetails, setPropertyDetails] = useState({});
  const [proposals, setProposals] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [installationTimeline, setInstallationTimeline] = useState('1 month');
  const { setIsSearchOver, setIsLooking } = useSearchAnimate();
  const [recommendations, setRecommendations] = useState([]);
  const [showPlantTypeSelection, setShowPlantTypeSelection] = useState(true);
  const [showPlantSizeSelection, setShowPlantSizeSelection] = useState(true);
  const [lookingForSolar, setLookingForSolar] = useState(true);
  const [userDetails, setUserDetails] = useState({});
  const [isPlantDetailsEditDialogOpen, setIsPlantDetailsEditDialogOpen] =
    useState(false);
  const [offersLoading, setOffersLoading] = useState(true);
  const navigate = useNavigate();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSupportButtonClick = () => {
    window.open('https://forms.gle/FYBfYapmfg77pZkz9', '_blank');
  };

  const handlePlantDetailsEditDialogOpen = () => {
    setIsPlantDetailsEditDialogOpen(true);
  };

  const handlePlantDetailsEditDialogClose = () => {
    setIsPlantDetailsEditDialogOpen(false);
  };

  const handlePlantDetailsEditDialogSave = async (plantDetails) => {
    setIsLoading(true);
    try {
      await updatePlantSize(propertyDetails?.id, plantDetails.plantSize);
      await updatePlantType(propertyDetails?.id, plantDetails.plantType);
      setPropertyDetails({
        ...propertyDetails,
        plantSize: plantDetails.plantSize,
        plantType: plantDetails.plantType,
      });
      setRecommendationsAndVendorsData(true);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const setRecommendationsAndVendorsData = async (
    refreshRecommendations = false,
  ) => {
    setOffersLoading(true);
    if (refreshRecommendations) {
      await generateRecommendations();
    }
    let recommendationsResponse = await getRecommendations();
    if (recommendationsResponse.length === 0) {
      await generateRecommendations();
      recommendationsResponse = await getRecommendations();
    }
    const recommendations = recommendationsResponse.filter(
      (recommendation) => recommendation.proposalConfig,
    );
    const vendorList = recommendationsResponse
      .filter((recommendation) => !recommendation.proposalConfig)
      .map((recommendation) => {
        return {
          ...recommendation?.vendor,
          recommendationId: recommendation.id,
        };
      });
    setVendorData(vendorList);
    setRecommendations(recommendations);
    setOffersLoading(false);
  };

  useEffect(() => {
    updateTitle('Dashboard | VaySolar');
    updateDescription(
      'VaySolar a platform for vendor and customer to unite world with solar power energy',
    );
  }, [updateDescription, updateTitle]);

  useEffect(() => {
    const onMount = async () => {
      try {
        const userDetails = getUserDetails();
        setUserDetails(userDetails);
        const data = await getUserProperties();
        setPropertyDetails(data[0]);
        setInstallationTimeline(data[0]?.installationTimeline);
        if (
          data[0]?.installationTimeline === InstallationTimeline.NOT_LOOKING
        ) {
          setIsSearchOver(false);
          setIsLooking(false);
          setLookingForSolar(false);
        }
        if (data[0]?.plantSize) {
          setShowPlantSizeSelection(false);
        }
        if (data[0]?.plantType) {
          setShowPlantTypeSelection(false);
        }
        if (
          data[0]?.plantType &&
          data[0]?.plantSize &&
          data[0]?.installationTimeline !== InstallationTimeline.NOT_LOOKING
        ) {
          const proposalsResponse = await getProposalsByUserId();
          setProposals(proposalsResponse);
          await setRecommendationsAndVendorsData();
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
        setOffersLoading(false);
      }
    };
    onMount();
  }, []);

  if (isLoading || offersLoading) {
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        height='100vh'
        sx={{
          flexGrow: 1,
          px: { sm: 3, xs: 1.5 },
          py: { sm: 3, xs: 2 },
          pb: { sm: 3, xs: 8 },
        }}
      >
        <CircularProgress color='primary' />
      </Box>
    );
  }

  const DashboardMainContent = () => {
    return (
      <>
        {propertyDetails?.plantSize &&
          propertyDetails?.plantType &&
          !offersLoading && (
            <Box mb={4}>
              <Stack
                direction={'row'}
                mt={2}
                mb={0.5}
                maxWidth={'300px'}
                alignItems={'center'}
              >
                <Typography variant='h5' fontWeight={'bold'}>
                  {propertyDetails.plantSize}kW{' '}
                  {propertyDetails.plantType.toLowerCase()} solar
                </Typography>
                <IconButton onClick={handlePlantDetailsEditDialogOpen}>
                  <Edit />
                </IconButton>
              </Stack>
              <Stack
                direction={'row'}
                alignItems={'center'}
                gap={1}
              >
                <Security
                  sx={{
                    color: 'primary.main',
                    fontSize: '42px',
                    marginRight: '2px',
                  }}
                />
                <Typography variant='h6'>5 year service warranty</Typography>
              </Stack>
            </Box>
          )}
        <DashboardStepper
          proposals={proposals}
          propertyDetails={propertyDetails}
          vendorData={vendorData}
          recommendations={recommendations}
          userDetails={userDetails}
        />
        <EditPlantDetailsDialog
          open={isPlantDetailsEditDialogOpen}
          handleClose={handlePlantDetailsEditDialogClose}
          propertyDetails={propertyDetails}
          handleSave={handlePlantDetailsEditDialogSave}
        />
      </>
    );
  };

  return (
    <DashboardLayout
      announcementBar={true}
      propertyDetails={propertyDetails}
      installationTimeline={installationTimeline}
    >
      <Container
        component='main'
        sx={{
          flexGrow: 1,
          pb: 2,
          paddingInline: { md: '24px', xs: '12px' },
          paddingTop: '13px',
        }}
      >
        {!lookingForSolar ? (
          <SolarSearch propertyDetails={propertyDetails} />
        ) : showPlantSizeSelection ? (
          <PlantSize propertyDetails={propertyDetails} />
        ) : showPlantTypeSelection ? (
          <UserGoal propertyId={propertyDetails?.id} />
        ) : (
          DashboardMainContent()
        )}
        <Stack alignItems='flex-start' mt={12}>
          <Typography
            sx={{
              fontSize: {
                xs: theme.typography.h5.fontSize,
                md: theme.typography.h4.fontSize,
              },
              fontWeight: 'bold',
            }}
          >
            All questions answered
          </Typography>
          <Stack
            alignItems={isMobile ? 'flex-start' : 'unset'}
            mt={3}
            flexDirection={isMobile ? 'column' : 'row'}
            width={'100%'}
            gap={isMobile ? '8px' : '25px'}
          >
            {options.map((option, index) => (
              <Card
                key={option.id}
                sx={{
                  cursor: 'pointer',
                  width: '100%',
                  marginTop: 2,
                  borderRadius: '10px',
                  boxShadow: '2px 2px 2px 2px #D3D3D3',
                  maxWidth: '500px',
                }}
              >
                <CardContent
                  sx={{
                    display: 'flex',
                    flex: 1,
                    height: '100%',
                  }}
                >
                  <Stack>
                    <Typography
                      sx={{
                        fontSize: {
                          xs: theme.typography.h5.fontSize,
                          md: theme.typography.h5.fontSize,
                        },
                        fontWeight: 'bold',
                        display: 'flex',
                        alignItems: 'center',
                        lineHeight: '3rem',
                      }}
                      mb={isMobile ? 1 : 2}
                    >
                      <Typography component={'span'}>{option.icon}</Typography>
                      {option.id === 1 ? (
                        <Typography
                          component={'span'}
                          pl={1}
                          fontWeight={'bold'}
                          fontSize={'18px'}
                        >
                          {option.title}
                        </Typography>
                      ) : (
                        <Typography
                          component={'span'}
                          pl={1.5}
                          fontWeight={'bold'}
                          fontSize={'18px'}
                        >
                          {option.title}
                        </Typography>
                      )}
                    </Typography>
                    <Stack
                      flexGrow={1}
                      gap={'24px'}
                      justifyContent={'space-between'}
                    >
                      <Typography
                        sx={{
                          color: theme.palette.secondary.light,
                          fontSize: {
                            xs: theme.typography.h6.fontSize,
                            md: theme.typography.h6.fontSize,
                          },
                          fontWeight: 'bold',
                        }}
                      >
                        {option.name}
                        <br />
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: {
                            xs: theme.typography.body2.fontSize,
                            md: theme.typography.body2.fontSize,
                          },
                          color: 'rgba(0, 0, 0, 0.38)',
                        }}
                      >
                        {option.details}
                      </Typography>
                    </Stack>
                    <Typography alignSelf={'flex-end'} py={0.5}>
                      <Link href={option.href} variant='body2' target='_blank'>
                        read more
                      </Link>
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            ))}
          </Stack>
          <Typography
            mt={6}
            sx={{
              fontSize: theme.typography.body2.fontSize,
              color: theme.palette.primary.main,
            }}
          >
            Not sure still?{' '}
            <Link
              onClick={() => {
                window.location.href =
                  'https://www.vaysolar.com/docs/?utm_source=landing-page&utm_medium=landing-page&utm_campaign=landing-page';
              }}
            >
              Here is a guide on how to buy solar
            </Link>
            <br />
            Unsure still?{' '}
            <Link onClick={handleSupportButtonClick}>Talk to an expert</Link>
            <br />
          </Typography>
        </Stack>
      </Container>
    </DashboardLayout>
  );
};
export default DashboardPage;
