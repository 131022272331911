import { Check, Person, Phone } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FeedIcon from '@mui/icons-material/Feed';
import GppGoodIcon from '@mui/icons-material/GppGood';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import React, { useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import vendor_placeholder from '../../Image/vendor_placeholder.png';
import {
  createMatch,
  customerInitiatesCallEvent,
  requestQuote,
  updateIsConvertedStatus,
} from '../../services/CustomerService.js';
import { getUserDetails } from '../../services/UserDetailsService.js';
import { INTERACTION } from '../../utils/app.constants.js';
import { Interaction } from '../../utils/app.enums.js';
import { trackGoogleAnalyticsEvent } from '../../utils/utils.js';
import { isEmpty } from '../constant.js';
import SiteVisitDialog from '../dashboard/SiteVisitDialog.js';
import VendorSummary from './VendorSummary.js';

export default function VendorHeader({
  vendor,
  customerId,
  siteVisitData,
  propertyId,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const [quoteStatus, setQuoteStatus] = useState(false);
  const [open, setOpen] = useState(false);

  const callPhoneNumber = async () => {
    if (isEmpty(siteVisitData)) {
      setOpen(true);
      return;
    }
    await customerInitiatesCallEvent(customerId, vendor?.id);
    window.open(`tel:+${vendor?.phoneNumber}`);
    trackGoogleAnalyticsEvent(
      INTERACTION,
      Interaction.CUSTOMER_INITIATED_CALL,
      {
        source: getUserDetails().id,
        target: vendor?.id,
        createdAt: new Date(),
      },
    );
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const openVendorProfile = () => {
    const vendorNameEncoded = vendor?.establishmentName.replace(/\s+/g, '-');
    const vendorInfo = `${vendorNameEncoded}_${vendor?.id}`;
    navigate(`/vendor-profile/${vendorInfo}`);
  };

  const handleQuote = async (vendorId = null, customerId = null) => {
    if (!isEmpty(siteVisitData)) {
      const res = await requestQuote(customerId, vendorId);
      await createMatch(customerId, vendorId);
      await updateIsConvertedStatus(vendor?.recommendationId, true);
      if (res) {
        setQuoteStatus(true);
        toast.success('Quote requested successfully');
      }
      return;
    }
    setOpen(true);
  };

  const handleNext = () => {
    navigate(`/site-details?id=${propertyId}&step=1`);
  };

  return (
    <>
      <Accordion
        expanded={expanded === 'panel1'}
        slotProps={{ transition: { timeout: 400 } }}
        onChange={handleChange('panel1')}
        sx={{
          paddingInline: { sm: '20px', xs: '2px' },
          borderRadius: '9px !important',
          boxShadow: 'none',
          border: '1px solid lightGray',
          '.MuiAccordionSummary-root': {
            alignItems: 'start',
          },
          '.MuiAccordionSummary-expandIconWrapper': {
            margin: '13px 0',
            marginLeft: '4px',
          },
        }}
      >
        <AccordionSummary
          expandIcon={
            <Button sx={{ borderRadius: '100%', minWidth: 'unset' }}>
              <ExpandMoreIcon />
            </Button>
          }
          aria-controls={`panel1-content`}
          id={`panel1-header`}
        >
          <Stack>
            <Stack direction={'row'} alignItems={'center'} spacing={2}>
              <Typography
                variant='h6'
                sx={{
                  fontWeight: 'bold',
                }}
              >
                {vendor?.establishmentName}
              </Typography>
              {vendor?.verificationStatus === 'VERIFIED' && (
                <GppGoodIcon
                  sx={{
                    color: 'primary.main',
                    fontSize: { sm: '34px', xs: '32px' },
                  }}
                />
              )}
            </Stack>
            <Stack direction={'column'} flex={1}>
              <Typography
                fontWeight='500'
                variant='caption'
                component={'p'}
                sx={{
                  color: 'text.secondary',
                }}
              >
                Serves in{' '}
                {vendor?.address?.locality && `${vendor.address.locality}, `}
                {vendor?.address?.state && `${vendor.address.state}`}
                {vendor?.address?.pinCode && ` - ${vendor.address.pinCode}`}
              </Typography>
            </Stack>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Stack
              direction={'row'}
              alignItems={'flex-start'}
              justifyContent={'space-between'}
            >
              <Box>
                {vendor?.suryagharEligible && (
                  <Chip
                    label='PM Suryaghar Empanelled'
                    size={isMobile ? 'small' : ''}
                    sx={{
                      width: 'fit-content',
                      p: '4px',
                      bgcolor: theme.palette.secondary.lighter,
                    }}
                  />
                )}
                <VendorSummary vendor={vendor} key={vendor.id} />
              </Box>
              {vendor.logoUrl && (
                <Box
                  component='img'
                  src={vendor?.logoUrl ? vendor?.logoUrl : vendor_placeholder}
                  alt='vendor logo'
                  sx={{
                    height: { xs: '96px', md: '120px' },
                    display: { xs: 'none', sm: 'flex' },
                  }}
                />
              )}
            </Stack>
            <Stack
              direction={'row'}
              gap={{ sm: 2, xs: 1.5 }}
              mt={2}
              justifyContent={'flex-end'}
              flexWrap={{ sm: 'nowrap', xs: 'wrap-reverse' }}
              ml={'auto'}
              maxWidth={550}
            >
              <Button
                variant='text'
                color='primary'
                disableElevation
                startIcon={<Phone />}
                onClick={callPhoneNumber}
                fullWidth
              >
                Call
              </Button>
              <Button
                variant='outlined'
                disableElevation
                startIcon={<Person />}
                fullWidth
                onClick={openVendorProfile}
              >
                View Profile
              </Button>
              <Button
                variant='contained'
                disableElevation
                onClick={() => handleQuote(vendor.id, customerId)}
                startIcon={quoteStatus ? <Check /> : <FeedIcon />}
                fullWidth
                disabled={quoteStatus || vendor?.isConverted}
              >
                {quoteStatus ? 'Request sent' : 'Request Quote'}
              </Button>
            </Stack>
          </Box>
        </AccordionDetails>
      </Accordion>
      <SiteVisitDialog open={open} setOpen={setOpen} handleNext={handleNext} />
      <Toaster position='bottom-center' />
    </>
  );
}
