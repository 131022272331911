import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';
import ImageIcon from '@mui/icons-material/Image';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import {
  Box,
  Card,
  CardContent,
  Divider,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ImageListWithTitle from './ImageListWithTitle';

const MiddleSec = ({ startLink, researchLink }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const handleSupportButtonClick = () => {
    window.open('https://forms.gle/FYBfYapmfg77pZkz9', '_blank');
  };

  const options = [
    {
      id: 1,
      title: 'Cost of solar',
      name: '₹45000 to 68000 per kW',
      icon: (
        <CurrencyRupeeIcon
          fontSize='large'
          sx={{ color: theme.palette.primary.main }}
        />
      ),
      details: 'Depends on size, technology, brands, structure, warranties',
      href: 'https://www.vaysolar.com/docs/research/cost/',
      production: '10 units',
    },
    {
      id: 2,
      title: 'Benefits of solar',
      name: '₹2-3 per unit (up to 75% less)',
      icon: (
        <EnergySavingsLeafIcon
          fontSize='large'
          sx={{ color: theme.palette.primary.main }}
        />
      ),
      details:
        "You can reduce your electricity bills by up to 90%. Property's value also increases in the market!",
      href: 'https://www.vaysolar.com/docs/research/benefits/',
      production: '20 units',
    },
    {
      id: 3,
      title: 'How solar works?',
      name: 'Cells create energy from sunlight',
      icon: (
        <SolarPowerIcon
          fontSize='large'
          sx={{ color: theme.palette.primary.main }}
        />
      ),
      details: 'Solar energy is the cheapest form of energy source available',
      href: 'https://www.vaysolar.com/docs/research/how-it-works/',
      production: '40 units',
    },
  ];

  const data = [
    {
      id: '1',
      title1: 'Yearly generation',
      value1: '1500',
      para1: 'AC always on for 1000+ hours',
      title2: 'At ₹8 per unit**',
      value2: '₹12,000+',
      para2: 'annual savings per 100 sq feet',
    },
    {
      id: '2',
      title1: 'Lifetime generation',
      value1: '25,000',
      para1: 'AC always on for 600+ days',
      title2: 'At ₹8 per unit**',
      value2: '₹2,00,000+ ',
      para2: 'lifetime savings per 100 sq feet',
    },
  ];

  return (
    <Box
      sx={{
        backgroundSize: { md: '70%', xs: '100%' },
        backgroundPosition: { md: 'right top', xs: 'center' },
        backgroundRepeat: 'no-repeat',
        minHeight: '100vh',
      }}
    >
      <Stack alignItems='flex-start' mt={5}>
        <Typography
          sx={{
            fontSize: {
              xs: theme.typography.h5.fontSize,
              md: theme.typography.h4.fontSize,
            },
            fontWeight: 'bold',
          }}
        >
          <QuestionMarkIcon
            fontSize='large'
            sx={{ color: theme.palette.primary.main }}
          />{' '}
          All questions answered
        </Typography>
        <Stack
          alignItems={isMobile ? 'flex-start' : 'unset'}
          mt={6}
          flexDirection={isMobile ? 'column' : 'row'}
          width={'100%'}
          gap={isMobile ? '8px' : '25px'}
        >
          {options.map((option) => (
            <Card
              key={option.id}
              sx={{
                cursor: 'pointer',
                width: '100%',
                marginTop: 2,
                borderRadius: '10px',
                boxShadow: '2px 2px 2px 2px #D3D3D3',
                maxWidth: '500px',
              }}
            >
              <CardContent
                sx={{
                  display: 'flex',
                  flex: 1,
                  height: '100%',
                }}
              >
                <Stack>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: theme.typography.h5.fontSize,
                        md: theme.typography.h5.fontSize,
                      },
                      fontWeight: 'bold',
                      display: 'flex',
                      alignItems: 'center',
                      lineHeight: '3rem',
                    }}
                    mb={isMobile ? 1 : 2}
                  >
                    <Typography component={'span'}>{option.icon}</Typography>
                    {option.id === 1 ? (
                      <Typography
                        component={'span'}
                        pl={1}
                        fontWeight={'bold'}
                        fontSize={'18px'}
                      >
                        {option.title}
                      </Typography>
                    ) : (
                      <Typography
                        component={'span'}
                        pl={1.5}
                        fontWeight={'bold'}
                        fontSize={'18px'}
                      >
                        {option.title}
                      </Typography>
                    )}
                  </Typography>
                  <Stack
                    flexGrow={1}
                    gap={'24px'}
                    justifyContent={'space-between'}
                  >
                    <Typography
                      sx={{
                        color: theme.palette.secondary.light,
                        fontSize: {
                          xs: theme.typography.h6.fontSize,
                          md: theme.typography.h6.fontSize,
                        },
                        fontWeight: 'bold',
                      }}
                    >
                      {option.name}
                      <br />
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: {
                          xs: theme.typography.body2.fontSize,
                          md: theme.typography.body2.fontSize,
                        },
                        color: 'rgba(0, 0, 0, 0.38)',
                      }}
                    >
                      {option.details}
                    </Typography>
                  </Stack>
                  <Typography alignSelf={'flex-end'} py={0.5}>
                    <Link href={option.href} variant='body2'>
                      read more
                    </Link>
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          ))}
        </Stack>
        <Typography
          mt={6}
          sx={{
            fontSize: theme.typography.body2.fontSize,
            color: theme.palette.primary.main,
          }}
        >
          All up to date?{' '}
          <Link onClick={() => navigate(startLink)}>Start my search </Link>
          <br />
          Not sure still?{' '}
          <Link
            onClick={() => {
              if (researchLink) {
                navigate(researchLink);
              } else {
                window.location.href =
                  'https://www.vaysolar.com/docs/?utm_source=landing-page&utm_medium=landing-page&utm_campaign=landing-page';
              }
            }}
          >
            Here is a guide on how to buy solar
          </Link>
          <br />
          Unsure still?{' '}
          <Link onClick={handleSupportButtonClick}>Talk to an expert</Link>
          <br />
        </Typography>
      </Stack>

      <Divider sx={{ mt: '50px' }} />
      <Stack alignItems='flex-start' mt={7} mb={5}>
        <Typography
          sx={{
            fontSize: {
              xs: theme.typography.h5.fontSize,
              md: theme.typography.h4.fontSize,
            },
            fontWeight: 'bold',
          }}
          mb={10}
        >
          <Typography
            sx={{
              fontSize: {
                xs: theme.typography.h5.fontSize,
                md: theme.typography.h4.fontSize,
              },
              color: theme.palette.secondary.light,
              fontWeight: 'bold',
              display: 'inline',
            }}
          >
            100
          </Typography>{' '}
          sq. feet for solar gives
        </Typography>

        <Stack
          alignItems={isMobile ? 'flex-start' : 'unset'}
          mt={2}
          maxWidth={'unset'}
          width={'100%'}
          flexDirection={'column'}
          gap={'8px'}
        >
          {data.map((data) => (
            <Card
              key={data.id}
              sx={{
                //
                cursor: 'pointer',
                width: '100%',
                marginTop: 2,
                borderRadius: '10px',
                boxShadow: '2px 2px 2px 2px #D3D3D3',
              }}
            >
              <CardContent sx={{ display: 'flex' }}>
                <Stack width={'50%'}>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: theme.typography.body2.fontSize,
                        md: theme.typography.h6.fontSize,
                      },
                      color: theme.palette.secondary.light,
                      fontWeight: 'bold',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {data.title1}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: theme.typography.h6.fontSize,
                        md: theme.typography.h4.fontSize,
                      },
                      fontWeight: 'bold',
                    }}
                    mb={isMobile ? 0.5 : '6px'}
                  >
                    {data.value1}&nbsp;
                    <Typography
                      sx={{
                        fontSize: theme.typography.h6.fontSize,
                        display: 'inline',
                        fontWeight: 'bold',
                      }}
                    >
                      units
                    </Typography>
                    <br />
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: theme.typography.body2.fontSize,
                        md: theme.typography.h6.fontSize,
                      },

                      color: theme.palette.secondary.light,
                      fontWeight: 'bold',
                    }}
                  >
                    {data.para1}
                  </Typography>
                </Stack>
                <Stack width={'50%'}>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: theme.typography.body2.fontSize,
                        md: theme.typography.h6.fontSize,
                      },
                      color: theme.palette.secondary.light,
                      fontWeight: 'bold',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {data.title2}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: theme.typography.h6.fontSize,
                        md: theme.typography.h4.fontSize,
                      },
                      fontWeight: 'bold',
                    }}
                    mb={isMobile ? 0.5 : '6px'}
                  >
                    {data.value2}
                    <br />
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: theme.typography.body2.fontSize,
                        md: theme.typography.h6.fontSize,
                      },

                      color: theme.palette.secondary.light,
                      fontWeight: 'bold',
                    }}
                  >
                    {data.para2}
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          ))}
        </Stack>
        <Typography
          mt={4}
          sx={{
            fontSize: {
              xs: theme.typography.body2.fontSize,
              md: theme.typography.body2.fontSize,
              color: theme.palette.primary.main,
            },
          }}
        >
          **taxes vary from 5% to 30% based on govt norms
        </Typography>
      </Stack>
      <Divider sx={{ mt: '50px' }} />
      <Stack gap = {'1.5rem'}alignItems='flex-start' mt={7} mb={5}>
        <Stack direction={'row'} sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem'
        }}>
             <ImageIcon
          sx={{
            fontSize: '2.3rem',
            color: theme.palette.secondary.light,
            
          }}/>
        <Typography
            sx={{
              fontSize: {
                xs: theme.typography.h5.fontSize,
                md: theme.typography.h4.fontSize,
              },
              fontWeight: 'bold',
              display: 'inline',
            }}
          >
           Site by VaySolar installers
          </Typography>
        </Stack>
          <ImageListWithTitle />
      </Stack>
    </Box>
  );
};

export default MiddleSec;
